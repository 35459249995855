<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <verify ref="modalVerify" @showToast="showToast" @mostrarDocumento="mostrarDocumento">
        <template v-slot:header><span></span></template>
      </verify>
      <Anular ref="modalAnular" @showToast="showToast" @mostrarDocumento="mostrarDocumento">
        <template v-slot:header><span></span></template>
      </Anular>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <strong>Facturas Manuales (de Contingencia)</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Nueva factura" type="button"
                        @click="create()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nueva factura
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>

                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-factura-nuevo.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Nueva factura
                    </a>
                  </CDropdownItem>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-factura-anular.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Anular Factura
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </div>
          <div class="card-body p-0">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import verify from "@/views/facturaenlinea/ModalVerify";
import Anular from "@/views/facturaenlinea/ModalAnular";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    verify,
    Anular,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Número Factura', datafield: "numeroFactura", width: '5%', cellsalign: 'center',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Número<br />Factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar número de factura"});
          }
        }, {
          text: 'Monto', datafield: "montoTotal", width: '7%', cellsalign: 'right', cellsformat: "f2",
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Monto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por monto"});
          }
        }, {
          text: 'Concepto', datafield: "concepto", width: '9%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Concepto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por concepto"});
          }
        }, {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '7%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br/>Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        }, {
          text: 'cajero', datafield: "cajero", width: '7%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Cajero</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por cajero "});
          }
        }, {
          text: 'Fecha Venta', datafield: "fecha_venta", width: '8%',
          cellsformat: 'yyyy-MM-dd HH:mm:ss', filtertype: 'range',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">fecha_venta</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por fecha venta "});
          }
        }, {
          text: 'carrera', datafield: "carrera", width: '14%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Carrera</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por carrera "});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '8%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '8%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '8%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '7%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "text-danger" : "";
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: "", width: '6%', columntype: "button",cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return "Ver Factura";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if ((rowData.estado !== 'VALIDADA' && rowData.estado !== 'ANULADA') || rowData.codigoRecepcion==null || rowData.codigoRecepcion=='') {
              modalPdf.$refs.modalVerify.mostrar(localStorage.sedeSeleccionada, rowData.id)
            } else {
              modalPdf.$refs.alert.show('Descargando documento');
              let time = new Date().getTime()
              let urlfactura=modalPdf.$apiAdress + '/api/facturaenlineamanual/' + rowData.id + '/facturapdf?token=' + localStorage.getItem('api_token')+'&timestamp='+time
              axios.get(
                  urlfactura,
                  {responseType: 'blob'}
              )
                  .then((response) => {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                  })
                  .catch(function (error) {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  });
            }
          }
        },
        {
          text: "", width: '6%', columntype: "button",
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "btn-hidden" : "btn-red";
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return (rowdata.estado === 'ANULADA') ? "Anulado" : "Anular";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.estado !== 'ANULADA') {
              modalPdf.$refs.modalAnular.mostrar(localStorage.sedeSeleccionada,rowData.id)
            } else {
              modalPdf.$refs.mensajeToast.makeToast('', 'No se puede anular 2 veces esta factura', 'danger');
            }
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/facturaenlineamanual/M?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'numeroFactura', type: "int"},
        {name: 'montoTotal', type: "float"},
        {name: 'concepto', type: "string"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'cajero', type: "string"},
        {name: 'fecha_venta', type: "date", format: 'yyyy-MM-dd H:mm:ss'},
        {name: 'carrera', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'codigoRecepcion', type: "string"},
      ],
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'numeroFactura',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    create: function () {
      this.$router.push({path: '/facturaenlineamanual/create'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    showToast(titulo,mensaje,color){
      this.$refs.mensajeToast.makeToast(titulo,mensaje,color)
    },
    mostrarDocumento(data){
      this.$refs.visorPDF.mostrarDocumento(data)
    }
  },
  mounted: function () {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      modalPdf.sede = data;
    });
  }
}
</script>